<template>
  <div>
    <v-btn
      class="mx-3"
      dark
      color="blue darken-3"
      @click="testLinkedIn()"
    >
      <v-icon left>
        mdi-linkedin
      </v-icon>
      Test LinkedIn
    </v-btn>
    <v-btn
      class="mx-3"
      dark
      color="blue darken-4"
      @click="testFacebook()"
    >
      <v-icon left>
        mdi-facebook
      </v-icon>
      Test Facebook
    </v-btn>
    <v-btn
      class="mx-3"
      dark
      color="blue lighten-3"
      @click="testTwitter()"
    >
      <v-icon left>
        mdi-twitter
      </v-icon>
      Test Twitter
    </v-btn>
    <br>
    <v-card
      class="my-4"
    >
      <v-card-text>
        <div
          style="height: 750px; overflow: scroll; overflow-wrap: break-word;"
        >
          <!-- {{ result }} -->
          <div
            v-html="result"
          />
        </div>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { socialService } from '@/shared/services'
export default {

  data () {
    return {
      result: null
    }
  },

  methods: {
    async testLinkedIn () {
      this.result = JSON.stringify(await socialService.linkedInTest(), undefined, 2)
      console.log(this.result)
    },
    async testFacebook () {
      this.result = JSON.stringify(await socialService.facebookTest(), undefined, 2)
      console.log(this.result)
    },
    async testTwitter () {
      this.result = JSON.stringify(await socialService.twitterTest(), undefined, 2)
      console.log(this.result)
    }
  }

}
</script>

<style lang="scss" scoped>

</style>
